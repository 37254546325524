<template>
    <div class="conlist">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <p>
                        <span>时间</span>
                        <el-date-picker
                            v-model="searchVal.month"
                            value-format="yyyy-MM"
                            type="month"
                            placeholder="全部"
                            size="small"
                            style="width: 170px"
                            @change="
                                (currentPage = 1),
                                    (pagesize = 20),
                                    getTableData()
                            "
                        >
                        </el-date-picker>
                    </p>
                    <p>
                        <span>开票公司</span>
                        <el-select
                            v-model="searchVal.company"
                            style="width: 170px"
                            clearable
                            size="small"
                            placeholder="全部"
                            @change="
                                (currentPage = 1),
                                    (pagesize = 20),
                                    getTableData()
                            "
                        >
                            <el-option
                                v-for="item in $tableDataHandle.selectTypeB()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-show="item.value != 9"
                            ></el-option>
                        </el-select>
                    </p>
                    <p>
                        <span>查询</span>
                        <el-input
                            size="small"
                            style="width: 220px"
                            placeholder="发票号码、开户名称、开票金额"
                            v-model="searchVal.fieldVal"
                            clearable
                        >
                        </el-input>
                        <el-button
                            @click="
                                (currentPage = 1),
                                    (pagesize = 50),
                                    getTableData()
                            "
                            type="primary"
                            size="small"
                            icon="el-icon-search"
                        ></el-button>
                    </p>
                </div>
                <div style="margin-bottom: 10px; width: 160px">
                    <!-- <el-button
                        type="primary"
                        style="margin-right: 16px; border-color: #2370eb; background: #2370eb"
                        size="small"
                        @click="(currentPage = 1), (pagesize = 50), getTableData()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="margin: 0; background: none; border-color: #d9d9d9; color: #333333"
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    > -->
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>我的发票</p>
            <span> </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="开票日期" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.invoiceTime
                            ? scope.row.invoiceTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="发票号码"
                prop="companyName"
                show-overflow-tooltip
                width="139"
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.invoiceNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="员工"
                width="80"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.collectionAdminName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="发票类型"
                width="150"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.invoiceType }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="开票名称"
                width="239"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.invoiceName }}</span>
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="开票金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{ scope.row.invoiceAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="不含税金额"
                width="80"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.taxExcludedAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="税额"
                prop="createTime"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.taxAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="开票公司"
                prop="adminName"
                width="209"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>
                        {{
                            $tableDataHandle.echoTypeB(scope.row.company)
                        }}</span
                    >
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box" v-if="isPage">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import { invoiceList } from '@/api/invoice/invoice.js';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    data() {
        return {
            searchVal: {
                statusVal: '',
                month: '',
            },
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isPage: false,
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        this.searchVal.month =
            this.$searchTime.DateUtil.getStartDayOfMonth().slice(0, 7);
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getTableData();
        },

        getTableData() {
            let data = {
                param: {
                    collectionAdminId: sessionStorage.getItem('adminId'),
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };

            if (this.searchVal.fieldVal && this.searchVal.fieldVal != '') {
                data.param.name = this.searchVal.fieldVal;
            }
            if (this.searchVal.month && this.searchVal.month != '') {
                data.param.month = this.searchVal.month;
            }
            if (this.searchVal.company && this.searchVal.company != '') {
                data.param.company = this.searchVal.company;
            }
            invoiceList(data).then((res) => {
                // console.log(res);
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },

        // 复制
        onCopy(item) {
            let url = item.expressNo;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        searchEmpty() {
            this.searchVal = {
                statusVal: '',
            };
            this.getTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
.conlist {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        padding: 10px 0 0 0;
        background: #fff;
        border-radius: 4px;
        font-size: 13px;
        .search-one {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                display: flex;
                flex-flow: wrap;
                align-items: center;
                p {
                    height: 100%;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    margin-right: 16px;
                    margin-top: 0;
                }
            }
        }
        span {
            font-size: 12px;
            font-weight: 550;
            color: #333333;
            margin: 0 16px;
        }
        button {
            background: #2370eb;
            border-radius: 2px;
            margin-right: 16px;
        }
    }

    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.content {
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 16px;
    color: #333;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.isRed {
    color: red;
}
</style>
