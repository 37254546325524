import http from '@/utils/http.js'

// 我的发票
export function invoiceList(data) {
    return http({
        url: "/jasoboss/invoice/list",
        method: "post",
        data
    })
}

// 我的发票统计
export function invoiceBrief(data) {
    return http({
        url: "/jasoboss/invoice/list/brief",
        method: "post",
        data
    })
}
// 我的发票导入
export function invoiceImport(data) {
    return http({
        url: "/jasoboss/invoice/import",
        method: "post",
        data
    })
}
// 发票新增
export function invoiceAdd(data) {
    return http({
        url: "/jasoboss/invoice/add",
        method: "post",
        data
    })
}
// 发票修改和作废
export function invoiceUpDate(data) {
    return http({
        url: "/jasoboss/invoice/update",
        method: "post",
        data
    })
}
// 发票修改和作废
export function invoiceGet(data) {
    return http({
        url: "/jasoboss/invoice/get",
        method: "post",
        data
    })
}